import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app'
import { useGuestStore } from '~/store/guest'

export default defineNuxtRouteMiddleware((to) => {
  if (to.meta.skipMiddleware) return

  const { gift } = useGuestStore()

  if (gift && gift.usage?.type === 'freeItem' && gift.isUsed) {
    return navigateTo('/redeemed')
  }
})
